import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Layout from '../components/layout';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Teams`}</h1>
    <p>{`Here's a description about the teams for Movax.`}</p>
    <h2>{`D1`}</h2>
    <p>{`Here's a description about D1.`}</p>
    <p><a parentName="p" {...{
        "href": "/schedule/d1"
      }}>{`See the D1 schedule `}{`→`}</a></p>
    <h2>{`30s`}</h2>
    <p>{`Here's a description about 30s.`}</p>
    <p><a parentName="p" {...{
        "href": "/schedule/30s"
      }}>{`See the 30s schedule `}{`→`}</a></p>
    <h2>{`40s`}</h2>
    <p>{`Here's a descriptiom about 40s.`}</p>
    <p><a parentName="p" {...{
        "href": "/schedule/40s"
      }}>{`See the 40s schedule `}{`→`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      